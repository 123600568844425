import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './main.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Visualizacion from './routes/Visualizacion';
import Posestratificacion from './routes/Posestratificacion';
import Muestreo from './routes/Muestreo';
import SingUp from './routes/SingUp';
import SingIn from './routes/SingIn';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UrlProvider } from './components/context/BaseUrl';
import config from './amplifyconfiguration.json';

Amplify.configure(config);

function App() {
  return (
    <Authenticator hideSignUp className="app-container">
      {({ signOut }) => (
        <BrowserRouter>
          <UrlProvider>
            <Routes>
              {/* <Route path="/sing-in" element={<SingIn />} />
                <Route path="/sing-up" element={<SingUp />} /> */}
              <Route
                path="/muestreo"
                element={<Muestreo signOut={signOut} />}
              />
              <Route
                path="/posestratificacion"
                element={<Posestratificacion signOut={signOut} />}
              />
              <Route
                path="/visualizacion"
                element={<Visualizacion signOut={signOut} />}
              />
              <Route path="/" element={<Visualizacion signOut={signOut} />} />
            </Routes>
          </UrlProvider>
          <ToastContainer autoClose={3000} />
        </BrowserRouter>
      )}
    </Authenticator>
  );
}

export default App;
