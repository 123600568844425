{
  "aws_project_region": "us-east-2",
  "aws_cognito_identity_pool_id": "us-east-2:d5c44426-5a57-46a3-8a56-d9768b7f8cce",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_nk2rhlVnA",
  "aws_user_pools_web_client_id": "4h09m0jsd1ruqg1fmcl1av4rpr",
  "oauth": {},
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ]
}